
window.initializeGmapGlobal = function () {
    var $gmaps = $('.gmap-contact');
    if($gmaps.length){
        initializeGmapContact();
    }
};
function pinSymbol($svg, $fillColor, $fillOpacity, $strokeColor, $strokeWeight, $scale) {
    if ($svg) {
        return {
            path: 'M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z',
            fillColor: $fillColor,
            fillOpacity: $fillOpacity,
            strokeColor: $strokeColor,
            strokeWeight: $strokeWeight,
            scale: $scale
        };
    } else {
        var $pinUrl = location.protocol+'//'+location.hostname+'/themes/impression-plan/assets/dest/img/map-pin.png';
        // var $pinUrl = "http://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2.png";
        return $pinUrl
    }
}
function initializeGmapContact () {
    /* Map Contact */
    var $gmap = $('.gmap-contact');
    if($gmap.length){
        // https://snazzymaps.com/
        var $styles = [{"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"color":"#444444"}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#e2e2e2"}]},{"featureType":"poi","elementType":"geometry","stylers":[{"visibility":"on"},{"color":"#8b888a"}]},{"featureType":"poi","elementType":"labels.text.fill","stylers":[{"color":"#444444"}]},{"featureType":"poi","elementType":"labels.icon","stylers":[{"color":"#cc2578"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":"-100"},{"lightness":"45"}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"},{"color":"#c5c5c5"}]},{"featureType":"road.highway","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"road.highway","elementType":"labels.text","stylers":[{"visibility":"off"}]},{"featureType":"road.highway.controlled_access","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"road.highway.controlled_access","elementType":"labels.icon","stylers":[{"color":"#a7a3a5"}]},{"featureType":"road.arterial","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"all","stylers":[{"visibility":"on"},{"color":"#cc2578"}]}];
        var infoWindows = [];
        $gmap.each(function(){
            // Affichage de la map
            var $gmap = $(this);
            var gmapContainer = $('.map-container', $gmap)[0];
            var $data = $('ul.map-data', $gmap);
            var mapLat = $data.data('lat');
            var mapLng = $data.data('lng');
            var mapLatLng = {lat: mapLat, lng: mapLng};
            var mapZoom = $data.data('zoom');
            var $pins = $('.pin', $data);
            mapOptions = {
                center: mapLatLng,
                mapTypeId: 'roadmap',
                gestureHandling: 'cooperative',
                zoomControl: true,
                zoomControlOptions: {
                    position: google.maps.ControlPosition.LEFT_BOTTOM
                },
                streetViewControl: false,
                zoom: mapZoom,
                mapTypeControl: false,
                mapTypeControlOptions: {
                    style: google.maps.MapTypeControlStyle.DROPDOWN_MENU
                },
                fullscreenControl: false,
                fullscreenControlOptions: {
                    position: google.maps.ControlPosition.TOP_LEFT
                }
            };
            var map = new google.maps.Map(gmapContainer, mapOptions);
            map.setOptions({styles: $styles});
            // Affichage des pins
            $pins.each(function(){
                var $color = '#cc2578';
                var $pin = $(this);
                var address = $pin.data('address');
                var pinLat = $pin.data('lat');
                var pinLng = $pin.data('lng');
                var pinLatLng = {lat: pinLat, lng: pinLng};
                var infoWindowContent = $($pin).html();
                var marker = new google.maps.Marker({
                    map: map,
                    position: pinLatLng,
                    title: address,
                    animation: google.maps.Animation.DROP,
                    icon: pinSymbol(1, $color, 1, $color, 1, 0.1)
                });
                var infowindow = new google.maps.InfoWindow({
                    content: '<div class="infowindowcontent">'+infoWindowContent+'</div>'
                });
                marker.addListener('click', function() {
                    closeAllInfoWindows();
                    //map.panTo(marker.getPosition());
                    infowindow.open(map, marker);
                });
                $pin[0].addEventListener('click', function() {
                    closeAllInfoWindows();
                    //map.panTo(marker.getPosition());
                    infowindow.open(map, marker);
                });
                infoWindows.push(infowindow);
            });
            // Recentrage de la map si plusieurs pins
            var bounds = new google.maps.LatLngBounds();
            var pinsLatLng = [];
            $pins.each(function(){
                var $pin = $(this);
                var lat = $pin.data('lat');
                var lng = $pin.data('lng');
                pinsLatLng.push( new google.maps.LatLng(lat, lng));
            });
            for(var i = 0; i < pinsLatLng.length; i++){
                bounds.extend(pinsLatLng[i]);
            }
            if(pinsLatLng.length > 1){
                map.fitBounds(bounds);
            }
        });
        function closeAllInfoWindows() {
            for (var i=0;i<infoWindows.length;i++) {
                infoWindows[i].close();
            }
        }
    }
};