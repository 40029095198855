//filesToUpload : tableau d'objets avec
// size (automatique lors de l'ajout du fichier)
// name (automatique lors de l'ajout du fichier)
// key : file.name + file.size
// formattedFilesize : taille du fichier formatée pour l'affichage
// nb_pages
// width
// height
// qty
// color
let filesToUpload = {};
const colors = [
    {id: 1, label: 'Couleur'},
    {id: 0, label: 'N&B'}
];
const folds = [
    {id: 0, label: 'Oui'},
    {id: 1, label: 'Non'}
];
const formatConfigPrice = {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
    currencyDisplay: 'symbol'
};
const formatConfig = {
    style: 'decimal',
    minimumFractionDigits: 2
};
const locales = 'fr-FR';

const maxAllowedSize = 32;
const maxLength = 5080;
const maxWidth = 914;

let $uploadArea;
let $uploadCta;
let $uploadStatus;
let $willowimpressionplan;
let carrier;
let $orderDetail;
let $cardRowMatrix;
let $modal;
let $detailedTotals;
const MIN_AREA_INVOICE = 0.5;

$(function () {
    let $html = $('main');
    $cardRowMatrix = $('.willowimpressionplan--cart--row--matrix');
    $uploadArea = $('.willowimpressionplan--form-upload--area');
    $uploadCta = $('.willowimpressionplan--form-upload--cta');
    $uploadStatus = $('.willowimpressionplan--form-upload--status');
    $orderDetail = $('.order-confirmation-table, #order-products');
    $willowimpressionplan = $('.willowimpressionplan--cart');
    $detailedTotals = $('.willowimpressionplan--cart--detailed-totals, .willowimpressionplan--cart--detailed-totals--cta, .willowimpressionplan--cart--rows');
    $modal = $('#modal-1');


    if ($uploadArea.length) {
        // si un panier a déjà été créé par le client alors on récupère les informations des produits
        // comme ça le client peut modifier son panier
        if ($products.length) {
            for (let p = 0; p < $products.length; p++) {
                filesToUpload[$products[p].key] = $products[p].data;
                filesToUpload[$products[p].key].id_product = $products[p].id_product;
                addCardRowFromMatrix($products[p].data, false);
                // updateRowWithFileInfos($products[p].data);
            }
        }
        
        carrier = $carrier;

        updateRowsWithProductListInfos($products);

        // Drag leave
        $uploadArea.on('dragleave', function (e) {
            preventDefault(e, 1);

            $uploadArea.removeClass('active');
            $uploadCta.find('span').html($translate['dragdropdown']);
        });

        // Drag over
        $html.on('dragover', function (e) {
            preventDefault(e, 1);

            cleanStatus();
            $uploadArea.addClass('active');
            $uploadCta.find('span').html($translate['drop']);
        });

        // Drop
        $html.on('drop', function (e) {
            preventDefault(e, 1);
            const files = e.originalEvent.dataTransfer.files;
            sendFilesToAdd(files);
        });

        // Open file selector on div click
        $uploadCta.on('click', function (e) {
            preventDefault(e, 1);
            $('.willowimpressionplan--form-upload--file').trigger('click');
        });

        // file selected
        $('.willowimpressionplan--form-upload--file').on('change', function (e) {
            preventDefault(e, 1);
            sendFilesToAdd(this.files);
        });

        $(document).on('change', '.willowimpressionplan--cart--enlargement input[type="number"]', async function (e) {
            preventDefault(e, 1);
            const fileKey = $(this).attr('data-key');
            const file = filesToUpload[fileKey];
            let enlargement = parseInt($(this).val());
            let inputMin = parseInt($(this).attr('min'));
            let inputMax = parseInt($(this).attr('max'));
            if (enlargement < inputMin) {
                enlargement = inputMin;
                $(this).val(enlargement);
            }
            if (enlargement > inputMax) {
                enlargement = inputMax;
                $(this).val(enlargement);
            }
            
            file.enlargement = enlargement;

            let newEnlargement = await checkEnlargement(file);

            if (newEnlargement) {
                file.enlargement = newEnlargement;
                enlargement = newEnlargement;
                $(this).val(newEnlargement);
            }

            filesToUpload[fileKey].enlargement = enlargement;
            $('input[name="product[' + fileKey + '][enlargement]"]').val(enlargement);
            changeAttributeDimensions(file);
            changeAttributeArea(file);
            changeAttributesPrices(file);
            changeDataValue(file);
            updateProductCart(file);
        });

        // Au clic sur la case à cocher agrandissement, on réinitialise la valeur de l'agrandissement à 100
        $(document).on('click', '.willowimpressionplan--cart--enlargement label', function (e) {
            if ($(e.target).is('svg,path')) {
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();

                showModal('Attention', 'Par défaut, le format affiché est le format de votre fichier.<br />Si vous souhaitez agrandir votre fichier, il suffit de changer le pourcentage d\'agrandissement et votre nouveau format apparaîtra.<br /><br />Attention, en agrandissant votre document, l\'échelle de celui-ci sera inutilisable.')
            }
        });
        $(document).on('change', '.willowimpressionplan--cart--enlargement input[type="checkbox"]', function (e) {
            preventDefault(e, 1);
            $(this).closest('.willowimpressionplan--cart--enlargement').find('input[type="number"]')
                .val(100)
                .trigger('change');
        });

        $(document).on('change', '.willowimpressionplan--cart--color select', function (e) {
            preventDefault(e, 1);
            const fileKey = $(this).attr('data-key');
            const file = filesToUpload[fileKey];
            const color = parseInt($(this).val());
            filesToUpload[fileKey].color = color;
            $('input[name="product[' + fileKey + '][color]"]').val(color);
            changeAttributesPrices(file);
            changeDataValue(file);
            updateProductCart(file);
        });

        $(document).on('change', '.willowimpressionplan--cart--quantity input[type="number"]', function (e) {
            preventDefault(e, 1);
            const fileKey = $(this).attr('data-key');
            const file = filesToUpload[fileKey];
            let qty = parseInt($(this).val());
            if (qty < 1) {
                qty = 1;
                $(this).val(qty);
            }

            filesToUpload[fileKey].qty = qty;
            $('input[name="product[' + fileKey + '][qty]"]').val(qty);
            changeAttributeArea(file);
            changeAttributesPrices(file);
            changeDataValue(file);
            updateProductCart(file);
        });

        $(document).on('click', '.willowimpressionplan--cart--remove', function (e) {
            preventDefault(e, 1);
            const fileKey = $(this).attr('data-key');
            const token = $('.willowimpressionplan--form-upload').attr('data-token');
            const file = filesToUpload[fileKey];
            deleteFile(token, file);
        });
    }

    if ($orderDetail.length) {
        // Open file selector on div click
        $uploadCta.on('click', function (e) {
            preventDefault(e, 1);
            $(this).closest('form').find('.willowimpressionplan--form-upload--file:first').trigger('click');
        });
        // file selected
        $('.willowimpressionplan--form-upload--file').on('change', function (e) {
            preventDefault(e, 1);
            sendFilesToEdit(this.files, this);
        });

    }
});


function cleanStatus() {
    $uploadStatus.html('');
}

function addStatus(text, isSuccess) {
    let $statusEl = $('<span />', {'class' :(isSuccess === false ? "txt-error" : "")})
        .html(text);
    $uploadStatus.append($statusEl);
}


async function sendFileToAdd(file) {
    let name;
    let percent = 0;
    try {
        let cartToken = $('.willowimpressionplan--form-upload').attr('data-token');
        let formData  = new FormData();
        let nameCleaned = await removeDiacritics(file.name);

        // On renomme le fichier en retirant les accents
        file = new File([file], nameCleaned, {type: file.type});
        name = file.name;

        formData.append('file', file, nameCleaned);
        formData.append('token', cartToken);

        // On vérifie l'extension du fichier
        if (!isValidExtension(name)) {
            throw 'Vous devez uploader un fichier de type PDF.';
        }

        if (!isValidSize(file)) {
            throw 'Le fichier ne doit pas dépasser la taille maximale autorisée de ' + maxAllowedSize + 'Mo.';
        }

        file.enlargement       = 100;
        file.color             = colors[0].id;//couleur
        file.qty               = $quantities[0];//1
        // file.folding           = 0;//Oui
        file.formattedFilesize = getFormatFilesize(file.size);
        file.key               = hex_md5(file.name + file.size);
        // file.filename          = file.name;//la propriété name n'est pas envoyée dans le json, donc on la met dans une autre propriété
        file.error             = false;

        await addCardRowFromMatrix(file, true);
        $willowimpressionplan.removeClass('willowimpressionplan--cart--none');

        let newFile = await readFile(file);
        let oldFile = await retrieveFileInArray(file);

        // Si un fichier avec le même nom existe dans le tableau
        if (oldFile) {
            console.log('file exists');
            throw 'Le fichier possède un nom identique à un autre fichier déjà uploadé.';

            // On vérifie que le nouveau fichier a le même nombre de pages
            if (await !hasSamePageNumber(oldFile, newFile)) {
                throw 'Le fichier n\'a pas le même nombre de pages que le fichier ayant déjà été uploadé.';
            }

            // On vérifie que chaque page a le même format
            if (await !hasSamePageFormat(oldFile, newFile)) {
                throw 'Le format des pages du fichier uploadé diffère de celui déjà uploadé.';
            }

            // On vérifie si le nouveau est de la même taille
            if (await hasSameSize(oldFile, newFile)) {
                throw 'Le fichier est identique à celui déjà uploadé avec ce nom.';
            }

            console.log('La taille du fichier est différente alors on remplace le fichier original');
        }
        else {
            console.log('file not exists');
        }

        // console.log('Ajout du fichier au tableau');
        // filesToUpload.push(file);

        // if (i === files.length) {
        //     console.error('2');
        //     resolve(uploadStatus);
        // }


        // On lance l'upload
        try {
            updateUploadProgression(file.key, 10, 'téléchargement du fichier en cours');
            await uploadFile(formData, file);
        } catch(error) {
            console.warn(error);
            throw 'Un problème est survenu pendant l\'upload du fichier';
        }

        await updateRowWithFileInfos(file);

        // On ajoute le produit au panier
        let addProductResult;
        try {
            addProductResult = await createProductCart(file);
            $products = addProductResult.product_list;
        } catch(error) {
            console.warn(error);
            let message = 'Un problème est survenu pendant l\'ajout du produit au panier';
            if (error.responseJSON && error.responseJSON.message) {
                message = error.responseJSON.message;
            }
            throw message;
        }

        await updateRowsWithProductListInfos($products);

        await addProductResultToTilesToUpload(addProductResult, file);

        $uploadCta.find('span').html($translate['dragdropdown']);


        return {
            'file' : name,
            'status' : 'success',
            'message': 'Transfert OK'
        };

    } catch (error) {

        removeCardRow(file.key, true);

        console.error(error);
        return {
            'file' : name,
            'status' : 'error',
            'message': error
        };

        // if (i === files.length -1) {
        //     console.error('1');
        //     resolve(uploadStatus);
        // }
    }
}

async function createCartIfNotExists() {
    return $.ajax({
        url     : 'modules/willowimpressionplan/assets/xhr/cart.php',
        type    : 'post',
        dataType: 'json'
    });
}

async function sendFilesToAdd(files) {
    let uploadStatus = [];

    $uploadArea.removeClass('active');
    $uploadCta.find('span').html($translate['upload']);
    cleanStatus();

    $detailedTotals.addClass('wait');

    scrollTo($uploadArea);

    // On crée le panier et on l'assigne au contexte s'il n'existe pas.
    // Cela corrige le bug en prod où, si le panier n'existe pas, un ajout en masse crée un panier par produit
    await createCartIfNotExists();

    // On convertit l'objet fileList en array
    files = [...files];

    await Promise.all(files.map(async (file) => {
        let status = await sendFileToAdd(file);
        uploadStatus.push(status);
    }));

    // for (let i = 0; i < files.length; ++i) {
    // for await (file of files) {
    //     // let file      = files[i];
    //     sendFileToAdd(file);
    // }

    let result = await refreshProductPrices();
    $products = result.product_list;

    await updateRowsWithProductListInfos($products);

    changeSummaryTotals();

    $detailedTotals.removeClass('wait');

    displayUploadStatus(uploadStatus);

}


async function addProductResultToTilesToUpload(addProductResult, file) {

    carrier = addProductResult.carrier;
    file.id_product = addProductResult.id_product;
    $('input[name="product[' + addProductResult.key + '][id_product]"]').val(addProductResult.id_product);

    $('.willowimpressionplan--cart--row[data-key="' + file.key + '"]').removeClass('new-file');

    // On ajoute le produit au tableau des items
    filesToUpload[file.key] = file;

}


async function sendFilesToEdit(files, context) {
    let $form = $(context).closest('form');
    let uploadStatus = [];
    let $uploadCta = $form.find('.willowimpressionplan--form-upload--cta');
    let name;

    $uploadStatus = $form.find('.willowimpressionplan--form-upload--status');

    $uploadCta.find('span').html($translate['upload']);

    cleanStatus();

    let file = files[0];
    try {
        let formData  = new FormData($form.get(0));
        let replace   = false;
        name          = file.name;

        // On vérifie l'extension du fichier
        if (!isValidExtension(name)) {
            // throw 'Vous devez uploader un fichier de type PDF.';
        }

        try {
            editFileResult = await editFile(formData, file);
        } catch(error) {
            throw error && error.responseJSON && error.responseJSON.message
                ? error.responseJSON.message
                : 'Un problème est survenu pendant l\'envoi du fichier.';
        }

        $uploadCta.find('span').html($translate['editpdffile']);
        uploadStatus.push({
            'file' : name,
            'status' : 'success',
            'message': 'Fichier modifié'
        });

        let $line = $form.closest('.order-line');
        $line.find('.product-name')
            .text(editFileResult.filename);
        $line.find('.product-link')
            .attr('title', editFileResult.filename)
            .attr('href', editFileResult.filepath)



    } catch (error) {
        console.error(error);
        $uploadCta.find('span').html($translate['editpdffile']);
        uploadStatus.push({
            'file' : name,
            'status' : 'error',
            'message': error
        });
    }

    displayUploadStatus(uploadStatus);

    scrollTo($uploadArea);

}


/**
 * Smooth scroll to an element
 * @param $elem
 */
function scrollTo($elem)
{
    if ($elem.length) {
        $('html,body').animate({scrollTop: $($elem).offset().top - 30}, 'slow');
    }
}

/**
 * Méthode qui affiche dans la page le résultat des différents statuts de téléchargement.
 * @param uploadStatus
 */
function displayUploadStatus(uploadStatus) {
    for (let i in uploadStatus) {
        let status = uploadStatus[i];
        let message = status.file
            ? status.file +' : <strong>'+ status.message + '</strong>'
            : '<strong>'+ status.message + '</strong>';
        addStatus(message, status.status !== "error");
    }
}



function changeAttributeArea(file) {
    let data = getAllData(file);
    let areaText = [];

    for (var format in data) {
        if (format === 'A4' || format === 'A3') {
            areaText.push(format + '&nbsp;:&nbsp;' + data[format]['totalSameFormatQty'] + '&nbsp;ex');
            delete data[format];
        }
    }

    if (Object.keys(data).length > 0) {
        areaText.push('Surface&nbsp;:&nbsp;' + numberFormat(array_sum(array_column(data, 'totalPrintArea'))) + 'm²');
    }

    $('.willowimpressionplan--cart--row[data-key="' + file.key + '"] .willowimpressionplan--cart--area').html(areaText.join('<br>'));
    refreshAreaColumn();
}


/**
 * Affiche la colonne surface si du contenu y est affiché
 */
function refreshAreaColumn() {
    let displayArea = false;

    $('.willowimpressionplan--cart--row .willowimpressionplan--cart--area').each(function() {
        if (this.innerHTML.trim().length > 0) {
            displayArea = true;
            return false; // breaks
        }
    });

    $('.willowimpressionplan--cart--area').toggle(displayArea);
}

function changeAttributeDimensions(file) {
    const dimensions = getAllDimensions(file, false);
    const keys = Object.keys(dimensions);
    let html = '';
    let dimension = '';
    for (let k = 0; k < keys.length; k++) {
        dimension += '<p>' + dimensions[keys[k]] + '</p>';
    }
    html += dimension;
    let $dimensionsContent = $('.willowimpressionplan--cart--row[data-key="' + file.key + '"] .willowimpressionplan--cart--print-dimensions--content');
    $dimensionsContent.empty().append(html);
    // $('input[name="product[' + file.key + '][dimensions]"]').val(html);
}

// function changeAttributeFolding(fileKey, enlargement) {
//     var $folding = 1;
//     var $html = '';
//     if (enlargement != 100) {
//         var $selectedYes = 'selected="selected"';
//         var $selectedNo = '';
//         $folding = 0;
//         if (filesToUpload[fileKey].folding) {
//             $selectedYes = '';
//             $selectedNo = 'selected="selected"';
//             $folding = 1;
//         }
//         $html += '<select name="willowimpressionplan--cart--folding[' + fileKey + ']" data-key="' + fileKey + '">';
//         $html += '<option label="Oui" value="0" ' + $selectedYes + '>' + $translate['yes'] + '</option>';
//         $html += '<option label="Non" value="1" ' + $selectedNo + '>' + $translate['no'] + '</option>';
//         $html += '</select>';
//         $html += '<span><i class="fas fa-angle-down"></i></span>';
//     } else {
//         filesToUpload[fileKey].folding = 1;
//         $html += 'Non';
//     }
//     $('.willowimpressionplan--cart--row[data-key="' + fileKey + '"] .willowimpressionplan--cart--folding').empty();
//     $('.willowimpressionplan--cart--row[data-key="' + fileKey + '"] .willowimpressionplan--cart--folding').append($html);
//     $('input[name="product[' + fileKey + '][folding]"]').val($folding);
// }

function changeAttributesPrices(file) {

    let data = getAllData(file);
    let totalPrice = array_sum(array_column(data, 'totalPrice'));
    let priceText = [];

    for (var format in data) {
        if (format === 'A4' || format === 'A3') {
            priceText.push(format + '&nbsp;:&nbsp;' + data[format]['calculationPrice'] + '€/ex');
            // delete data[format];
        }
        else {
            priceText.push(format + '&nbsp;:&nbsp;' + data[format]['calculationPrice'] + '€/m²');
        }
    }

    // if (Object.keys(data).length > 0) {
    //     priceText.push('Surface&nbsp;:&nbsp;' + numberFormat(array_sum(array_column(data, 'calculationPrice'))) + '€/m²');
    // }


    // $('.willowimpressionplan--cart--row[data-key="' + file.key + '"] .willowimpressionplan--cart--calculation-price').html(priceText.join('<br>'));
    // $('.willowimpressionplan--cart--row[data-key="' + file.key + '"] .willowimpressionplan--cart--price-wt').text(formatPrice(totalPrice));
}

function changeDataValue(file) {
    let data = stringifyFileObject(file);
    data = setBase64(data);
    $('input[name="product[' + file.key + '][data]"]').val(data);
}

async function updateProductCart(file) {

    $detailedTotals.addClass('wait');

    await doUpdateProduct(file);

    let result = await refreshProductPrices();
    $products = result.product_list;
    carrier = result.carrier;

    await updateRowsWithProductListInfos($products);

    changeSummaryTotals();

    $detailedTotals.removeClass('wait');

}

function doUpdateProduct(file) {
    let formData = $('.willowimpressionplan--cart--add-to-cart').serializeObject();
    formData.key = file.key;

    return $.ajax({
        url     : 'modules/willowimpressionplan/assets/xhr/update.php',
        type    : 'post',
        data    : formData,
        dataType: 'json'
    });
}



function changeSummaryTotals() {
    let shipping;
    let shippingText;

    if (!carrier || false == carrier) {
        shippingText = 'Merci de vous connecter';
        shipping     = 0;
    } else {
        shipping = carrier['price_without_tax'];
        if (shipping == 0) {
            shippingText = 'Offert';
        } else {
            shippingText = 'à partir de ' + formatPrice(carrier['price_without_tax']);
        }
    }

    let totalPriceWt = 0;
    for (let p = 0; p < $products.length; p++) {
        let product = $products[p];
    // for (let fileKey in filesToUpload) {
    //     const file = filesToUpload[fileKey];
        // totalPriceWt += roundPrice(getUnitPrice(file) * file.qty);
        totalPriceWt += roundPrice(getTotalPrice(product));
    }

    let vat = roundPrice((totalPriceWt + shipping) * parseFloat($tax['rate']) / 100);
    let totalPrice = roundPrice(totalPriceWt + shipping + vat);


    $('.willowimpressionplan--cart--detailed-totals--total-wt').text(formatPrice(totalPriceWt));
    $('.willowimpressionplan--cart--detailed-totals--shipping').text(shippingText);
    $('.willowimpressionplan--cart--detailed-totals--tax').text(formatPrice(vat));
    $('.willowimpressionplan--cart--detailed-totals--total').text(formatPrice(totalPrice));
}

function roundPrice(price, decimals) {
    if (typeof decimals === 'undefined') {
        decimals = 2;
    }
    return parseFloat((Math.round(price * 100) / 100).toFixed(decimals));
}

function formatPrice(price) {
    return numberFormat(price, formatConfigPrice);
}


function preventDefault(e, propagation) {
    e.preventDefault();
    if (propagation) {
        e.stopPropagation();
    }
}

function setBase64(data) {
    return window.btoa(unescape(encodeURIComponent(data)));
}

function getBase64(data) {
    return window.atob(escape(decodeURIComponent(data)));
}

/**
 * Lit le fichier PDF et récupère les pages du fichier
 * file : le fichier pdf
 * end : booléen indiquant si c'est le dernier fichier traité
 */
async function readFile(file) {
    let percent = 5;
    updateUploadProgression(file.key, percent,  'Lecture du fichier');
    let fileContent = await readFileContent(file);

    updateUploadProgression(file.key, ++percent, 'Analyse du fichier');
    file = await createPages(fileContent, file);

    return file;
}


function readFileContent(file) {
    return new Promise(function(resolve, reject) {
        let reader = new FileReader();
        reader.onload = () => {
            resolve(reader.result);
        };
        // reader.addEventListener("progress", progressHandler, false);
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
    });
}


/**
 * Fonction utilisant la nouvelle verison de l'API PDF.js
 * (incompatible avec IE, y compris avec Babel)
 * @param file
 * @returns {Promise<void>}
 */
// function readFileContentV2(file) {
//     return new Promise(function(resolve, reject) {
//         let reader = new FileReader();
//         reader.onload = (e) => {
//             resolve(e.target.result);
//         };
//         reader.file = file;
//         reader.onerror = reject;
//         reader.readAsDataURL(file);
//     });
// }


async function createPages(arrayBuffer, file) {
    const bytes = new Uint8Array(arrayBuffer);
    const length = bytes.byteLength;
    const buffer = new ArrayBuffer(length);
    const uint8Array = new Uint8Array(buffer);
    const modulo = Math.floor(length / 20);
    for (let i = 0; i < length; i++) {
        // if ( i%modulo === 0 ) {
        //     updateUploadProgression(file.key, ++percent, 'Analyse du fichier : ' + Math.floor(i / length * 100) + '%');
        // }
        uint8Array[i] = String.fromCharCode(bytes[i]).charCodeAt(0);
    }
    file.id_product = 0;
    file.pages = await getPdfPagesV2(uint8Array);
    file = await populateInfoFileFromPages(file);

    return file;
}


/**
 * Fonction utilisant la nouvelle verison de l'API PDF.js
 * (incompatible avec IE, y compris avec Babel)
 * @param pdf
 * @returns {Promise<void>}
 */
// async function createPagesV2(fileContent, file) {
//     // let PDFJS = window['pdfjs-dist/build/pdf'];
//     let PDFJS = window.pdfjsLib;
//     console.log(PDFJS);
//     // The workerSrc property shall be specified.
//     PDFJS.GlobalWorkerOptions.workerSrc = '/themes/impression-plan/assets/pdf.worker.js';
//
//     let loadingTask = PDFJS.getDocument(fileContent);
//     loadingTask.onPassword = (callback, reason) => {
//         throw 'PASSWOazdazdRD';
//         console.warn('PASSWORD REQUIRED');
//         console.warn(reason);
//     };
//     return loadingTask.promise.then(async function(pdf) {
//         file.pages = await getPdfPages(pdf);
//         console.log(file);
//         file = await populateInfoFileFromPages(file);
//         console.log(file);
//
//         return file;
//     }).catch(function(error) {
//         console.log(error)
//
//         if(error.name == 'PasswordException') {
//             throw 'Le fichier est protégé par un mot de passe. Vous pouvez utiliser un outil en ligne comme <a href="https://smallpdf.com/unlock-pdf" target="_blank">SmallPDF</a> pour retirer le mot de passe, puis retenter une importation.';
//         }
//     });
// }


/**
 * On vérifie que le fichier est un pdf
 */
function isValidExtension(name) {
    let array = name.match(/\.([^\.]+)$/);
    let extension = array ? array[1].toLowerCase() : '';
    return extension === 'pdf';
}

/**
 * On vérifie que le fichier ne dépasse pas le poids max autorisé
 */
function isValidSize(file) {
    return file.size < (maxAllowedSize*1024*1024);
}

/**
 * Renvoie si le fichier existe déjà dans le tableau des fichiers du panier
 * @param file
 * @returns {Promise<boolean>}
 */
async function fileAlreadyExists(file) {
    let existingFile = await retrieveFileInArray(file);

    return existingFile.length > 0 ? existingFile : false;
}


/**
 * Renvoie le fichier s'il existe déjà dans le tableau des fichiers du panier
 * @param file
 * @returns {Promise<*[]>}
 */
async function retrieveFileInArray(file) {
    for (let fileKey in filesToUpload) {
        if (filesToUpload[fileKey].name === file.name) {
            return filesToUpload[fileKey];
        }
    }
    return false;
}


/**
 * Renvoie si les 2 fichiers en paramètres ont le même nombre de pages
 * @param oldFile
 * @param newFile
 * @returns {boolean}
 */
function hasSamePageNumber(oldFile, newFile) {
    return newFile.nb_pages === oldFile.nb_pages;
}

/**
 * Renvoie si les 2 fichiers en paramètres ont la même taille
 * @param oldFile
 * @param newFile
 * @returns {boolean}
 */
function hasSameSize(oldFile, newFile) {
    return newFile.size === oldFile.size;
}

/**
 * Renvoie si les 2 fichiers en paramètres ont le même format de pages (A3, A4, etc...)
 * @param oldFile
 * @param newFile
 * @returns {boolean}
 */
async function hasSamePageFormat(oldFile, newFile) {
    for (let i = 0; i < newFile.pages.length; i++) {
        // if (!isA3orIsA4($newFile.pages[$i].width, $newFile.pages[$i].height)) {
        if (await (newFile.pages[i].width !== oldFile.pages[i].width || newFile.pages[i].height !== oldFile.pages[i].height)) {
            return false;
        }
    }
    return true;
}

/**
 * On remplace le fichier existant
 */
function replaceFile(newFile, i) {
    newFile.enlargement = 100;
    newFile.color = colors[0].id;//couleur
    newFile.qty = $quantities[0];//1
    // newFile.folding = 0;//Oui
    newFile.formattedFilesize = getFormatFilesize(newFile.size);
    newFile.key = hex_md5(newFile.name + newFile.size);
    // newFile.filename = newFile.name;//la propriété name n'est pas envoyée dans le json, donc on la met dans une autre propriété
    newFile.error = false;
    filesToUpload[i] = newFile;
}

/** Renvoie la taille formatée du fichier avec l'unité (o, Ko, Mo ou Go)
 * size : taille du fichier en octets
 */
function getFormatFilesize(size) {
    if (size != null) {
        let i = 0;
        while (size > 1000 && i < 4) {
            size = size / 1024;
            i++;
        }
        const unites = ['o', 'Ko', 'Mo', 'Go'];
        size = numberFormat(size, formatConfig);
        return size + ' ' + unites[i];
    } else {
        return 'Not detected';
    }
}

/*
 * Interface avec la librairie PDF
 */
async function getPdfPages(data) {
    const scale      = 96 / 72;
    const pdf        = new PDFDoc(data);
    const pagesCount = pdf.numPages;
    if (pagesCount > 0) {
        let result = [];
        for (let i = 1; i <= pagesCount; i++) {
            let page = pdf.getPage(i);
            result.push({
                page  : i,
                width : Math.round(page.view.width
                    * scale * 0.264583333),
                height: Math.round(page.view.height
                    * scale * 0.264583333)
            });
        }

        return result;
    } else {
        return [{
            page  : 1,
            width : 0,
            height: 0
        }];
    }
}

/**
 * Fonction utilisant la nouvelle verison de l'API PDF.js
 * (incompatible avec IE, y compris avec Babel)
 * @param pdf
 * @returns {Promise<void>}
 */
async function getPdfPagesV2(data) {
    const scale = 96 / 72 * 0.264583333;

    let PDFJS = window['pdfjs-dist/build/pdf'];
    // let PDFJS = window.pdfjsLib;
    // The workerSrc property shall be specified.
    // PDFJS.GlobalWorkerOptions.workerSrc = '/themes/impression-plan/assets/pdf.worker.js';
    PDFJS.GlobalWorkerOptions.workerSrc = '//cdn.jsdelivr.net/npm/pdfjs-dist@2.6.347/build/pdf.worker.js';

    let loadingPDF = PDFJS.getDocument({ data: data, password: '' });
    const promisePageCount = loadingPDF.promise.then(function(pdf_doc) {
        return pdf_doc;
    });

    let pagesCount = 0;
    let pdf_doc = '';
    await promisePageCount.then((value) => {
        pagesCount = value.numPages;
        pdf_doc = value;
    });
    
    let contents = [];
    let promises = [];

    if (pagesCount > 0) {
        let result = [];
        for (let i = 1; i <= pagesCount; i++) {
            let deferred = $.Deferred(); //Create a deferred object
            promises.push(deferred.promise()); //push promise to the list

            pdf_doc.getPage(i).then(function(page) {
                let viewport = page.getViewport({scale: scale});
                result.push({
                    page: i,
                    width: Math.round(viewport.width),
                    height: Math.round(viewport.height)
                });

                deferred.resolve(); //resolve the deferred object
            });
        }

        await Promise.all(promises);
        return result;
    } else {
        return [{
            page: 1,
            width: 0,
            height: 0
        }];
    }
}

/**
 * contrôler que toutes les pages ont le même format. Sinon renvoyer une erreur
 */
function fileContainsPagesWithDifferentFormat(file) {
    if (file.pages.length > 1) {
        for (let i = 1; i < file.pages.length; i++) {
            if (file.pages[i].width != file.pages[0].width || file.pages[i].height != file.pages[0].height) {
                return true;
            }
        }
    }
    return false;
}

async function populateInfoFileFromPages(file) {
    file.id_product  = 0;
    file.nb_pages    = file.pages.length;
    file.width      = file.pages[0].width;
    file.height     = file.pages[0].height;
    file.format     = getFileAreaToString(file.width, file.height);
    file.dimensions = getAllDimensions(file, true);
    file.area       = getFileArea(file);
    // if (isA3orIsA4(file.width, file.height)) {
    //     file.folding = 1;//Non
    // }
    if (await fileContainsPagesWithDifferentFormat(file)) {
        console.log('Le PDF contient des pages ayant un format différent');
        file.format = 'Multiple';
    }

    return file;
}

/**
 * Calcule la surface en m² d'un fichier en fonction de la hauteur et la largeur en mm, du nombre de pages et de l'agrandissement souhaité
 * (on considère que toutes les pages ont le même format, sinon on retourne false)
 */
function getFileArea(file) {
    if (isLessMeasureByUnit(file)) {
        return false;
    }

    const getCoefficientSurface = 1000000;
    let area = 0;
    for (let i = 0; i < file.nb_pages; i++) {
        // area += file.qty * (file.pages[i].width * file.enlargement / 100) * (file.pages[i].height * file.enlargement / 100) / getCoefficientSurface;
        area += (file.pages[i].width * file.enlargement / 100) * (file.pages[i].height * file.enlargement / 100) / getCoefficientSurface;
    }
    return area;
}

function getFileAreaToString(width, height) {
    if (isA4(width, height)) {
        return 'A4';
    } else if (isA3(width, height)) {
        return 'A3';
    } else {
        return width + 'mm x ' + height + 'mm';
    }
}

function isA4(width, height) {
    return (width === 297 && height === 210) || (width === 210 && height === 297);
}

function isA3(width, height) {
    return (width === 297 && height === 420) || (width === 420 && height === 297);
}

function isLessA4(width, height) {
    return (width <= 297 && height <= 210) || (width <= 210 && height <= 297);
}

function isLessA3(width, height) {
    return (width <= 297 && height <= 420) || (width <= 420 && height <= 297);
}

function isA3orIsA4(width, height) {
    return isA3(width, height) || isA4(width, height);
}

function isLessA3orIsLessA4(width, height) {
    return isLessA3(width, height) || isLessA4(width, height);
}


function checkEnlargement(file) {
    let newEnlargement   = false;

    if (isMeasureByUnit(file)) {
        let width          = file.width * file.enlargement / 100;
        let height         = file.height * file.enlargement / 100;
        let tmpEnlargement   = false;

        if (Math.max(width, height) > maxLength) {
            tmpEnlargement   = Math.floor(maxLength / Math.max(page.width, page.height) * 100);
            newEnlargement   = newEnlargement ? Math.min(newEnlargement, tmpEnlargement) : tmpEnlargement;
        }
        if (Math.min(width, height) > maxWidth) {
            tmpEnlargement   = Math.floor(maxWidth / Math.min(file.width, file.height) * 100);
            newEnlargement   = newEnlargement ? Math.min(newEnlargement, tmpEnlargement) : tmpEnlargement;
        }
    } else {
        for (let i = 0; i < file.nb_pages; i++) {
            let page             = file.pages[i];
            let width            = page.width * file.enlargement / 100;
            let height           = page.height * file.enlargement / 100;
            let pageEnlargement = false;
            let tmpEnlargement   = false;
            if (Math.max(width, height) > maxLength) {
                tmpEnlargement   = Math.floor(maxLength / Math.max(page.width, page.height) * 100);
                pageEnlargement   = pageEnlargement ? Math.min(pageEnlargement, tmpEnlargement) : tmpEnlargement;
            }
            if (Math.min(width, height) > maxWidth) {
                tmpEnlargement   = Math.floor(maxWidth / Math.min(page.width, page.height) * 100);
                pageEnlargement   = pageEnlargement ? Math.min(pageEnlargement, tmpEnlargement) : tmpEnlargement;
            }

            if (pageEnlargement) {
                newEnlargement = newEnlargement ? Math.min(newEnlargement, pageEnlargement) : pageEnlargement;
            }

        }
    }

    return newEnlargement;

}

        /**
 * on récupère les différentes dimensions des pages
 */
function getAllDimensions(file, json) {
    let dimension;
    let dimensionKey;
    let dimensions = [];
    let tmpDimensions = [];
    if (isMeasureByUnit(file)) {
        let width = file.width * file.enlargement / 100;
        let height = file.height * file.enlargement / 100;
        const format = isA4(width, height) ? 'A4' : 'A3';
        dimensions[format] = format;
    }
    else {
        for (let i = 0; i < file.nb_pages; i++) {
            let page = file.pages[i];
            let width = page.width * file.enlargement / 100;
            let height = page.height * file.enlargement / 100;

            if (isLessA3orIsLessA4(width, height)) {
                const format = isLessA4(width, height) ? 'A4' : 'A3';
                dimensionKey = format;
                dimension    = format;
            }
            else {
                dimensionKey = (page.width * file.enlargement / 100) + 'x' + (page.height * file.enlargement / 100);
                dimension = (page.width * file.enlargement / 100) + 'mm x ' + (page.height * file.enlargement / 100) + 'mm';
            }

            if (tmpDimensions[dimensionKey]) {
                tmpDimensions[dimensionKey] += 1;
            } else {
                tmpDimensions[dimensionKey] = 1;
            }
            dimensions[dimensionKey] = dimension + ' (x' + tmpDimensions[dimensionKey] + ')';

        }
    }
    if (json) {
        let dimensionsJson = [];
        let keys = Object.keys(dimensions);
        for (let k = 0; k < keys.length; k++) {
            dimensionsJson.push({
                id: k,
                value: dimensions[keys[k]]
            });
        }
        return dimensionsJson;
    }
    return dimensions;
}


function getPageSizes(file) {
    let pageSizes = [];
    for (let i = 0; i < file.nb_pages; i++) {
        let page        = file.pages[i];
        let fileWidth  = page.width;
        let fileHeight = page.height;

        let dimensionKey = fileWidth+'x'+fileHeight;

        if (!pageSizes[dimensionKey]) {
            pageSizes[dimensionKey] = 0;
        }
        pageSizes[dimensionKey] += 1;
    }
    return pageSizes;

}


function getAllData(file) {

    let data = [];
    let tmpDimensions = [];
    let pageSizes = getPageSizes(file);

    for (let size in pageSizes) {
        let qty = pageSizes[size];
        let [fileWidth, fileHeight] = size.split('x');

        let printWidth = fileWidth * file.enlargement / 100;
        let printHeight = fileHeight * file.enlargement / 100;
        let pageArea;

        if (isLessA3orIsLessA4(printWidth, printHeight)) {
            let isLessA4Var = isLessA4(printWidth, printHeight);
            const format = isLessA4Var ? 'A4' : 'A3';
            printWidth  = isLessA4Var ? 210 : 297;
            printHeight = isLessA4Var ? 297 : 420;
            dimensionKey = format;
            dimension    = format;
            suffix = '€/ex';
            pageArea = getPageArea(printWidth, printHeight);
        }
        else {
            dimensionKey = printWidth + 'x' + printHeight;
            dimension = printWidth + 'mm x ' + printHeight + 'mm';
            suffix = '€/m²';
            pageArea       = getPageArea(printWidth, printHeight);
        }

        if (!tmpDimensions[dimensionKey]) {
            tmpDimensions[dimensionKey] = 0;
        }
        tmpDimensions[dimensionKey] += qty;


        data[dimensionKey] = {
            fileWidth         : fileWidth,
            fileHeight        : fileHeight,
            enlargement       : file.enlargement,
            printWidth        : printWidth,
            printHeight       : printHeight,
            printAreaByPage   : pageArea,
            pagesSameFormatQty: qty,
            fileSameFormatQty : file.qty,
            totalSameFormatQty: file.qty * qty,
            printAreaByFormat : pageArea * qty,
            totalPrintArea    : Math.round(pageArea * qty * file.qty * 1000) / 1000,
            formatString      : dimension + ' (x' + tmpDimensions[dimensionKey] + ')',
        };

        let calculationPrice = getCalculationPriceByPageSize(printWidth, printHeight, file.color, data[dimensionKey]['totalSameFormatQty']);
        data[dimensionKey]['calculationPriceToString'] = data[dimensionKey]['formatString'] + '&nbsp;: ' + number_format(calculationPrice, 2, ',', '') + '&nbsp;' + suffix;

        let totalPrice = isLessA3orIsLessA4(printWidth, printHeight)
            //prix par exemplaire
            ? data[dimensionKey]['totalSameFormatQty'] * calculationPrice
            //prix au metre carre
            : calculationPrice * (Math.max(MIN_AREA_INVOICE, data[dimensionKey]['totalPrintArea']));

        data[dimensionKey]['calculationPrice'] = calculationPrice;
        data[dimensionKey]['totalPrice'] = Math.round(totalPrice * 100) / 100;

    }

    return data;

}


/**
 * Retourne le prix unitaire défini en fonction de la couleur et du format.
 * Si format A3 ou A4, c'est un prix par exemplaire.
 * Sinon c'est un prix au mètre carré.
 *
 * @param file
 * @returns {number}
 */
function getCalculationPriceByPageSize(printWidth, printHeight, isColor, qty) {

    let keyFormat;
    let area;
    if (isLessA4(printWidth, printHeight)) {
        keyFormat = 'A4';
    } else if (isLessA3(printWidth, printHeight)) {
        keyFormat = 'A3';
    }
    else {
        keyFormat = 'Surface';
        area = getPageArea(printWidth, printHeight);
    }


    let keyColor = $translate['bw'].replace('&amp;', '&');
    if (isColor) {
        keyColor = $translate['color'];
    }

    let quantity = Math.max(MIN_AREA_INVOICE, area ? area * qty : qty);
    let calculationPrice = 0;

    outer_loop:
        for (let i = 0; i < $unitPriceConfig.length; i++) {
            if ($unitPriceConfig[i]['format'] == keyFormat && $unitPriceConfig[i]['color'] == keyColor) {
                let prices = $unitPriceConfig[i]['prices'];
                for (let fromQty in prices) {
                    if (fromQty > quantity) {
                        break outer_loop;
                    }
                    calculationPrice = prices[fromQty];
                }
            }
        }

    return calculationPrice;

}

function getPageArea(fileWidth, fileHeight, enlargement) {
    if (typeof enlargement === 'undefined') {
        enlargement = 100;
    }

    let getCoefficientSurface = 1000000;
    let width = fileWidth * enlargement / 100;
    let height = fileHeight * enlargement / 100;

    return (width * height) / getCoefficientSurface;
}

/**
 * Retourne le prix unitaire défini en fonction de la couleur et du format.
 * Si format A3 ou A4, c'est un prix par exemplaire.
 * Sinon c'est un prix au mètre carré.
 *
 * @param file
 * @returns {number}
 */
function getCalculationPrice(file) {

    const width = file.width * file.enlargement / 100;
    const height = file.height * file.enlargement / 100;

    let keyFormat = $translate['area'];
    let keyColor = $translate['bw'].replace('&amp;', '&');
    let calculationPrice = 0;
    if (file.format == 'Multiple') {
        keyFormat = $translate['area'];
    } else if (isLessA4(width, height)) {
        keyFormat = 'A4';
    } else if (isLessA3(width, height)) {
        keyFormat = 'A3';
    }
    if (file.color) {
        keyColor = $translate['color'];
    }
    let qty = Math.max(MIN_AREA_INVOICE, file.area ? file.area * file.qty : file.qty);

    outer_loop:
    for (let i = 0; i < $unitPriceConfig.length; i++) {
        if ($unitPriceConfig[i]['format'] == keyFormat && $unitPriceConfig[i]['color'] == keyColor) {
            let prices = $unitPriceConfig[i]['prices'];
            for (let fromQty in prices) {
                if (fromQty > qty) {
                    break outer_loop;
                }
                calculationPrice = prices[fromQty];
            }
        }
    }

    return roundPrice(calculationPrice);
}

/**
 * Retourne le prix de calcul avec l'unité (ex : 2,39 €/m² ou 0,15 €/ex)
 *
 * @param file
 * @returns {string}
 */
function getCalculationPriceToString(file) {
    const calculationPriceToString = numberFormat(getCalculationPrice(file), formatConfig);
    const suffix = isLessMeasureByUnit(file) ? '€/ex' : '€/m²';

    return calculationPriceToString + ' ' + suffix;
}


/**
 * Retourne le prix d'impression d'un fichier
 *
 * @param product
 * @returns {number|*}
 */
function getTotalPrice(product) {
    let data = typeof(product.data) === 'object' ? product.data : JSON.parse(getBase64(product.data.replace(/=+$/g, '')));
    let totalPrice = array_sum(array_column(data.dataBySize, 'totalPrice'));

    return roundPrice(totalPrice, 3);
}


/**
 * Retourne si le prix doit être calculé par exemplaire (dans le cas contraire, le prix devra être calculé par surface)
 *
 * @param file
 * @returns {*|boolean}
 */
function isMeasureByUnit(file) {
    const width = file.width * file.enlargement / 100;
    const height = file.height * file.enlargement / 100;

    return isA3orIsA4(width, height) && file.format !== 'Multiple';
}

function isLessMeasureByUnit(file) {
    const width = file.width * file.enlargement / 100;
    const height = file.height * file.enlargement / 100;

    return isLessA3orIsLessA4(width, height) && file.format !== 'Multiple';
}


// Sending AJAX request and upload file
function uploadFile(formdata, file) {
    return new Promise(function (resolve, reject) {
        let ajax = new XMLHttpRequest();
        ajax.upload.addEventListener("progress", function(event) {
            let uploadPercent = Math.floor(event.loaded / event.total * 100);
            updateUploadProgression(file.key, uploadPercent, 'téléchargement du fichier en cours : ' + uploadPercent + '%');
        }, false);
        ajax.onload = function () {
            if (this.status >= 200 && this.status < 300) {
                resolve(ajax.response);
            } else {
                reject({
                    status: this.status,
                    statusText: ajax.statusText
                });
            }
        };
        ajax.onerror = function () {
            reject({
                status: this.status,
                statusText: ajax.statusText
            });
        };
        ajax.open("POST", "modules/willowimpressionplan/assets/xhr/upload.php");
        ajax.send(formdata);
    });
}

function updateUploadProgression(fileKey, percent, statusText) {
    const $progress = $('.willowimpressionplan--cart--row.new-file[data-key="' + fileKey + '"] .willowimpressionplan--cart--row--progress');

    $progress.find('.line')[0].style.width = percent+'%';
    $progress.find('.bg').text(statusText);
}



// Sending AJAX request and upload file
function editFile(formdata) {
    return $.ajax({
        url: 'modules/willowimpressionplan/assets/xhr/editFile.php',
        type: 'post',
        data: formdata,
        contentType: false,
        processData: false,
        dataType: 'json',
    });
}

/**
 * Ajoute une ligne dans la vue du panier
 * @param file
 */
async function addCardRowFromMatrix(file, isNewFile) {
    const $rows = $('.willowimpressionplan--cart--body .willowimpressionplan--cart--rows');
    const fileKey = file.key;

    let $line = $cardRowMatrix.clone();

    /**
     * Modification du HTML
     */
    $line = $line.html(function(i, oldHTML) {
        return oldHTML.replace(/__PRODUCT_KEY__/g, fileKey);
    });

    $line.find('.willowimpressionplan--cart--file-name').html(file.name);
    if (isNewFile) {
        $line.find('.willowimpressionplan--cart--row').addClass('new-file');
    }

    $rows.append($line.html());

}

function updateRowWithFileInfos(file) {
    const fileKey = file.key;
    const cartToken = $('.willowimpressionplan--form-upload').attr('data-token');

    let $line = $('.willowimpressionplan--cart--row[data-key="' + fileKey + '"]');

    $line.find('.willowimpressionplan--cart--file-nb-pages').html(file.nb_pages);
    $line.find('.willowimpressionplan--cart--file-format').html(file.format);

    if (file.enlargement != 100) {
        $line.find('.willowimpressionplan--cart--enlargement input[type="checkbox"]').attr('checked', 'checked');
    }
    $line.find('.willowimpressionplan--cart--enlargement input[type="number"]').attr('value', file.enlargement);

    $line.find('.willowimpressionplan--cart--color select option[value="'+(file.color == "1" ? 1 : 0)+'"]').attr('selected', 'selected');
    // $line.find('.willowimpressionplan--cart--quantity select option[value="'+file.qty+'"]').attr('selected', 'selected');
    $line.find('.willowimpressionplan--cart--quantity input').attr('value', file.qty);

    // $line.find('.willowimpressionplan--cart--area').html(file.area ? numberFormat(file.area, formatConfig) + ' m²' : '');


    /**
     * Modification des champs hidden
     */
    $line.find('[name$="id_product]"]').val(file.id_product);
    $line.find('[name$="name]"]').val(file.name);
    $line.find('[name$="format]"]').val(file.format);
    $line.find('[name$="enlargement]"]').val(file.enlargement);
    $line.find('[name$="color]"]').val(file.color);
    $line.find('[name$="qty]"]').val(file.qty);
    $line.find('[name$="token]"]').val(cartToken);
    // $line.find('[name$="folding]"]').val(file.folding);
    // $line.find('[name$="nb_pages]"]').val(file.nb_pages);
    // $line.find('[name$="area]"]').val(file.area);

    $line.find('[disabled]').prop('disabled', false);

    // let data = stringifyFileObject(file);
    // data = setBase64(data);
    // $line.find('[name$="data]"]').val(data);

    changeAttributeDimensions(file);
    changeAttributeArea(file);
    changeAttributesPrices(file);
    changeDataValue(file);
}

function updateRowsWithProductListInfos(productList) {
    for (let p = 0; p < productList.length; p++) {
        let product = productList[p];

        const fileKey   = product.key;
        const cartToken = $('.willowimpressionplan--form-upload').attr('data-token');

        let $line = $('.willowimpressionplan--cart--row[data-key="' + fileKey + '"]');

        $line.find('.willowimpressionplan--cart--file-nb-pages').html(product.nb_pages);
        $line.find('.willowimpressionplan--cart--file-format').html(product.format);

        if (product.enlargement != 100) {
            $line.find('.willowimpressionplan--cart--enlargement input[type="checkbox"]').attr('checked', 'checked');
        }
        $line.find('.willowimpressionplan--cart--enlargement input[type="number"]').attr('value', product.enlargement);

        $line.find('.willowimpressionplan--cart--color select option[value="' + (product.color == "1" ? 1 : 0) + '"]').attr('selected', 'selected');
        // $line.find('.willowimpressionplan--cart--quantity select option[value="'+file.qty+'"]').attr('selected', 'selected');
        $line.find('.willowimpressionplan--cart--quantity input').attr('value', product.quantity);



        // $line.find('.willowimpressionplan--cart--area').html(file.area ? numberFormat(file.area, formatConfig) + ' m²' : '');


        /**
         * Modification des champs hidden
         */
        $line.find('[name$="id_product]"]').val(product.id_product);
        $line.find('[name$="name]"]').val(product.name);
        $line.find('[name$="format]"]').val(product.format);
        $line.find('[name$="enlargement]"]').val(product.enlargement);
        $line.find('[name$="color]"]').val(product.color);
        $line.find('[name$="qty]"]').val(product.quantity);
        $line.find('[name$="token]"]').val(cartToken);
        // $line.find('[name$="folding]"]').val(file.folding);
        // $line.find('[name$="nb_pages]"]').val(file.nb_pages);
        // $line.find('[name$="area]"]').val(file.area);

        $line.find('[disabled]').prop('disabled', false);


        // changeAttributeDimensions(file);
        let $dimensionsContent = $('.willowimpressionplan--cart--row[data-key="' + fileKey + '"] .willowimpressionplan--cart--print-dimensions--content');
        $dimensionsContent.html(product.print_dimensions.replace(/\//g, '<br>'));


        // changeAttributeArea(file);
        let data = typeof(product.data) === 'object' ? product.data : JSON.parse(getBase64(product.data.replace(/=+$/g, '')));
        let areaText = [];

        let dataBySize = Object.assign({}, data.dataBySize);

        for (let format in dataBySize) {
            if (format === 'A4' || format === 'A3') {
                areaText.push(format + '&nbsp;:&nbsp;' + dataBySize[format]['totalSameFormatQty'] + '&nbsp;ex');
                delete dataBySize[format];
            }
        }

        if (Object.keys(dataBySize).length > 0) {
            areaText.push('Surface&nbsp;:&nbsp;' + numberFormat(array_sum(array_column(dataBySize, 'totalPrintArea'))) + 'm²');
        }

        $('.willowimpressionplan--cart--row[data-key="' + fileKey + '"] .willowimpressionplan--cart--area').html(areaText.join('<br>'));


        refreshAreaColumn();


        // changeAttributesPrices(file);
        changeDataValue(data);
        let totalPrice = array_sum(array_column(data.dataBySize, 'totalPrice'));
        let priceText = [];

        for (let format in data.dataBySize) {
            if (format === 'A4' || format === 'A3') {
                priceText.push(format + '&nbsp;:&nbsp;' + data.dataBySize[format]['calculationPrice'] + '€/ex');
                // delete data[format];
            }
            else {
                priceText.push(format + '&nbsp;:&nbsp;' + data.dataBySize[format]['calculationPrice'] + '€/m²');
            }
        }

        $('.willowimpressionplan--cart--row[data-key="' + fileKey + '"] .willowimpressionplan--cart--calculation-price').html(priceText.join('<br>'));
        $('.willowimpressionplan--cart--row[data-key="' + fileKey + '"] .willowimpressionplan--cart--price-wt').text(formatPrice(totalPrice));

    }
}


/**
 * Ajout le produit dans le panier
 */
function createProductCart(file) {
    let formData = $('.willowimpressionplan--cart--add-to-cart').serializeObject();
    formData.keyAdded = file.key;

    return $.ajax({
        url: 'modules/willowimpressionplan/assets/xhr/order.php',
        type: 'post',
        data: formData,
        dataType: 'json',
    });
}


/**
 * Recalcule les tarifs de chaque élément du panier
 */
function refreshProductPrices() {
    return $.ajax({
        url: 'modules/willowimpressionplan/assets/xhr/refreshProductPrices.php',
        type: 'post',
        dataType: 'json',
    });
}

function isSessionStorageExists($key) {
    if (window.sessionStorage) {
        $storage = window.sessionStorage;
        if ($storage.getItem($key)) {
            return true;
        }
    }
    return false;
}


/**
 * Supprime une ligne dans la vue du panier
 * @param file
 */
async function removeCardRow(fileKey, onlyIfNewFile) {
    let $line = $('.willowimpressionplan--cart--row[data-key="' + fileKey + '"]');
    if (onlyIfNewFile) {
        $line = $line.filter('.new-file');
        if ($line.length ===0) {
            return;
        }
    }

    $line.remove();
}

async function removeFromFilesToUpload(fileKey) {
    if (filesToUpload[fileKey]) {
        delete filesToUpload[fileKey];
        refreshAreaColumn();
        changeSummaryTotals();
    }
    if (Object.keys(filesToUpload).length <= 0) {
        $willowimpressionplan.addClass('willowimpressionplan--cart--none');
    }
}



async function deleteFile(token, file) {
    if (file.id_product) {
        $detailedTotals.addClass('wait');

        await doDeleteProduct(token, file);

        removeCardRow(file.key);
        removeFromFilesToUpload(file.key);

        let result = await refreshProductPrices();
        $products = result.product_list;
        carrier = result.carrier;

        await updateRowsWithProductListInfos($products);

        changeSummaryTotals();

        $detailedTotals.removeClass('wait');
    }
    else {
        removeCardRow(file.key);
        removeFromFilesToUpload(file.key);
    }
}



function doDeleteProduct(token, file) {
    return $.ajax({
        url     : 'modules/willowimpressionplan/assets/xhr/delete.php',
        type    : 'post',
        data    : 'name=' + file.name + '&id_product=' + file.id_product + '&token=' + token,
        dataType: 'json',
        error: function(e) {
            alert(e);
            console.error(e);
        }
    });
}



// Bytes conversion
function convertSize(size) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (!size) {
        return '0 Byte';
    }
    const i = parseInt(Math.floor(Math.log(size) / Math.log(1024)));
    return Math.round(size / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

function numberFormat(number, config) {
    return new Intl.NumberFormat(locales, config).format(number);
}


function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms || 1000));
}

function stringifyFileObject(file) {
    const obj = {};
    for (let key in file) {
        if (typeof file[key] === 'function') {
            continue;
        }
        obj[key] = file[key];
    }

    return JSON.stringify(obj);
}



function hideModal() {
    $modal.removeClass('success error');
    MicroModal.close($modal.attr('id'));
}
function showModal(title, html, modalClass) {
    if ($modal.is(':visible')) {
        hideModal();
    }
    $modal.addClass(modalClass);
    $modal.find('header h2').text(title || '');
    $modal.find('main').html(html || '');
    MicroModal.show($modal.attr('id'));
}
function showModalSuccess(title, html) {
    showModal(title, html, 'success');
}
function showModalError(title, html) {
    showModal(title, html, 'error');
}

function number_format (number, decimals, dec_point, thousands_sep) {
    // Strip all characters but numerical ones.
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}

function array_column (input, ColumnKey, IndexKey = null) {
    if (input !== null && (typeof input === 'object' || Array.isArray(input))) {
        const newarray = [];
        if (typeof input === 'object') {
            const temparray = [];
            for (const key of Object.keys(input)) {
                temparray.push(input[key])
            }
            input = temparray;
        }
        if (Array.isArray(input)) {
            for (const key of input.keys()) {
                if (IndexKey && input[key][IndexKey]) {
                    if (ColumnKey) {
                        newarray[input[key][IndexKey]] = input[key][ColumnKey]
                    } else {
                        newarray[input[key][IndexKey]] = input[key]
                    }
                } else {
                    if (ColumnKey) {
                        newarray.push(input[key][ColumnKey])
                    } else {
                        newarray.push(input[key])
                    }
                }
            }
        }
        return newarray;
    }
}

function array_sum (array) {
    let key;
    let sum = 0;
    // input sanitation
    if (typeof array !== 'object') {
        return null
    }
    for (key in array) {
        if (!isNaN(parseFloat(array[key]))) {
            sum += parseFloat(array[key])
        }
    }
    return sum;
}

async function removeDiacritics (str) {

    var defaultDiacriticsRemovalMap = [
        {'base':'A', 'letters':/[\u0041\u24B6\uFF21\u00C0\u00C1\u00C2\u1EA6\u1EA4\u1EAA\u1EA8\u00C3\u0100\u0102\u1EB0\u1EAE\u1EB4\u1EB2\u0226\u01E0\u00C4\u01DE\u1EA2\u00C5\u01FA\u01CD\u0200\u0202\u1EA0\u1EAC\u1EB6\u1E00\u0104\u023A\u2C6F]/g},
        {'base':'AA','letters':/[\uA732]/g},
        {'base':'AE','letters':/[\u00C6\u01FC\u01E2]/g},
        {'base':'AO','letters':/[\uA734]/g},
        {'base':'AU','letters':/[\uA736]/g},
        {'base':'AV','letters':/[\uA738\uA73A]/g},
        {'base':'AY','letters':/[\uA73C]/g},
        {'base':'B', 'letters':/[\u0042\u24B7\uFF22\u1E02\u1E04\u1E06\u0243\u0182\u0181]/g},
        {'base':'C', 'letters':/[\u0043\u24B8\uFF23\u0106\u0108\u010A\u010C\u00C7\u1E08\u0187\u023B\uA73E]/g},
        {'base':'D', 'letters':/[\u0044\u24B9\uFF24\u1E0A\u010E\u1E0C\u1E10\u1E12\u1E0E\u0110\u018B\u018A\u0189\uA779]/g},
        {'base':'DZ','letters':/[\u01F1\u01C4]/g},
        {'base':'Dz','letters':/[\u01F2\u01C5]/g},
        {'base':'E', 'letters':/[\u0045\u24BA\uFF25\u00C8\u00C9\u00CA\u1EC0\u1EBE\u1EC4\u1EC2\u1EBC\u0112\u1E14\u1E16\u0114\u0116\u00CB\u1EBA\u011A\u0204\u0206\u1EB8\u1EC6\u0228\u1E1C\u0118\u1E18\u1E1A\u0190\u018E]/g},
        {'base':'F', 'letters':/[\u0046\u24BB\uFF26\u1E1E\u0191\uA77B]/g},
        {'base':'G', 'letters':/[\u0047\u24BC\uFF27\u01F4\u011C\u1E20\u011E\u0120\u01E6\u0122\u01E4\u0193\uA7A0\uA77D\uA77E]/g},
        {'base':'H', 'letters':/[\u0048\u24BD\uFF28\u0124\u1E22\u1E26\u021E\u1E24\u1E28\u1E2A\u0126\u2C67\u2C75\uA78D]/g},
        {'base':'I', 'letters':/[\u0049\u24BE\uFF29\u00CC\u00CD\u00CE\u0128\u012A\u012C\u0130\u00CF\u1E2E\u1EC8\u01CF\u0208\u020A\u1ECA\u012E\u1E2C\u0197]/g},
        {'base':'J', 'letters':/[\u004A\u24BF\uFF2A\u0134\u0248]/g},
        {'base':'K', 'letters':/[\u004B\u24C0\uFF2B\u1E30\u01E8\u1E32\u0136\u1E34\u0198\u2C69\uA740\uA742\uA744\uA7A2]/g},
        {'base':'L', 'letters':/[\u004C\u24C1\uFF2C\u013F\u0139\u013D\u1E36\u1E38\u013B\u1E3C\u1E3A\u0141\u023D\u2C62\u2C60\uA748\uA746\uA780]/g},
        {'base':'LJ','letters':/[\u01C7]/g},
        {'base':'Lj','letters':/[\u01C8]/g},
        {'base':'M', 'letters':/[\u004D\u24C2\uFF2D\u1E3E\u1E40\u1E42\u2C6E\u019C]/g},
        {'base':'N', 'letters':/[\u004E\u24C3\uFF2E\u01F8\u0143\u00D1\u1E44\u0147\u1E46\u0145\u1E4A\u1E48\u0220\u019D\uA790\uA7A4]/g},
        {'base':'NJ','letters':/[\u01CA]/g},
        {'base':'Nj','letters':/[\u01CB]/g},
        {'base':'O', 'letters':/[\u004F\u24C4\uFF2F\u00D2\u00D3\u00D4\u1ED2\u1ED0\u1ED6\u1ED4\u00D5\u1E4C\u022C\u1E4E\u014C\u1E50\u1E52\u014E\u022E\u0230\u00D6\u022A\u1ECE\u0150\u01D1\u020C\u020E\u01A0\u1EDC\u1EDA\u1EE0\u1EDE\u1EE2\u1ECC\u1ED8\u01EA\u01EC\u00D8\u01FE\u0186\u019F\uA74A\uA74C]/g},
        {'base':'OI','letters':/[\u01A2]/g},
        {'base':'OO','letters':/[\uA74E]/g},
        {'base':'OU','letters':/[\u0222]/g},
        {'base':'P', 'letters':/[\u0050\u24C5\uFF30\u1E54\u1E56\u01A4\u2C63\uA750\uA752\uA754]/g},
        {'base':'Q', 'letters':/[\u0051\u24C6\uFF31\uA756\uA758\u024A]/g},
        {'base':'R', 'letters':/[\u0052\u24C7\uFF32\u0154\u1E58\u0158\u0210\u0212\u1E5A\u1E5C\u0156\u1E5E\u024C\u2C64\uA75A\uA7A6\uA782]/g},
        {'base':'S', 'letters':/[\u0053\u24C8\uFF33\u1E9E\u015A\u1E64\u015C\u1E60\u0160\u1E66\u1E62\u1E68\u0218\u015E\u2C7E\uA7A8\uA784]/g},
        {'base':'T', 'letters':/[\u0054\u24C9\uFF34\u1E6A\u0164\u1E6C\u021A\u0162\u1E70\u1E6E\u0166\u01AC\u01AE\u023E\uA786]/g},
        {'base':'TZ','letters':/[\uA728]/g},
        {'base':'U', 'letters':/[\u0055\u24CA\uFF35\u00D9\u00DA\u00DB\u0168\u1E78\u016A\u1E7A\u016C\u00DC\u01DB\u01D7\u01D5\u01D9\u1EE6\u016E\u0170\u01D3\u0214\u0216\u01AF\u1EEA\u1EE8\u1EEE\u1EEC\u1EF0\u1EE4\u1E72\u0172\u1E76\u1E74\u0244]/g},
        {'base':'V', 'letters':/[\u0056\u24CB\uFF36\u1E7C\u1E7E\u01B2\uA75E\u0245]/g},
        {'base':'VY','letters':/[\uA760]/g},
        {'base':'W', 'letters':/[\u0057\u24CC\uFF37\u1E80\u1E82\u0174\u1E86\u1E84\u1E88\u2C72]/g},
        {'base':'X', 'letters':/[\u0058\u24CD\uFF38\u1E8A\u1E8C]/g},
        {'base':'Y', 'letters':/[\u0059\u24CE\uFF39\u1EF2\u00DD\u0176\u1EF8\u0232\u1E8E\u0178\u1EF6\u1EF4\u01B3\u024E\u1EFE]/g},
        {'base':'Z', 'letters':/[\u005A\u24CF\uFF3A\u0179\u1E90\u017B\u017D\u1E92\u1E94\u01B5\u0224\u2C7F\u2C6B\uA762]/g},
        {'base':'a', 'letters':/[\u0061\u24D0\uFF41\u1E9A\u00E0\u00E1\u00E2\u1EA7\u1EA5\u1EAB\u1EA9\u00E3\u0101\u0103\u1EB1\u1EAF\u1EB5\u1EB3\u0227\u01E1\u00E4\u01DF\u1EA3\u00E5\u01FB\u01CE\u0201\u0203\u1EA1\u1EAD\u1EB7\u1E01\u0105\u2C65\u0250]/g},
        {'base':'aa','letters':/[\uA733]/g},
        {'base':'ae','letters':/[\u00E6\u01FD\u01E3]/g},
        {'base':'ao','letters':/[\uA735]/g},
        {'base':'au','letters':/[\uA737]/g},
        {'base':'av','letters':/[\uA739\uA73B]/g},
        {'base':'ay','letters':/[\uA73D]/g},
        {'base':'b', 'letters':/[\u0062\u24D1\uFF42\u1E03\u1E05\u1E07\u0180\u0183\u0253]/g},
        {'base':'c', 'letters':/[\u0063\u24D2\uFF43\u0107\u0109\u010B\u010D\u00E7\u1E09\u0188\u023C\uA73F\u2184]/g},
        {'base':'d', 'letters':/[\u0064\u24D3\uFF44\u1E0B\u010F\u1E0D\u1E11\u1E13\u1E0F\u0111\u018C\u0256\u0257\uA77A]/g},
        {'base':'dz','letters':/[\u01F3\u01C6]/g},
        {'base':'e', 'letters':/[\u0065\u24D4\uFF45\u00E8\u00E9\u00EA\u1EC1\u1EBF\u1EC5\u1EC3\u1EBD\u0113\u1E15\u1E17\u0115\u0117\u00EB\u1EBB\u011B\u0205\u0207\u1EB9\u1EC7\u0229\u1E1D\u0119\u1E19\u1E1B\u0247\u025B\u01DD]/g},
        {'base':'f', 'letters':/[\u0066\u24D5\uFF46\u1E1F\u0192\uA77C]/g},
        {'base':'g', 'letters':/[\u0067\u24D6\uFF47\u01F5\u011D\u1E21\u011F\u0121\u01E7\u0123\u01E5\u0260\uA7A1\u1D79\uA77F]/g},
        {'base':'h', 'letters':/[\u0068\u24D7\uFF48\u0125\u1E23\u1E27\u021F\u1E25\u1E29\u1E2B\u1E96\u0127\u2C68\u2C76\u0265]/g},
        {'base':'hv','letters':/[\u0195]/g},
        {'base':'i', 'letters':/[\u0069\u24D8\uFF49\u00EC\u00ED\u00EE\u0129\u012B\u012D\u00EF\u1E2F\u1EC9\u01D0\u0209\u020B\u1ECB\u012F\u1E2D\u0268\u0131]/g},
        {'base':'j', 'letters':/[\u006A\u24D9\uFF4A\u0135\u01F0\u0249]/g},
        {'base':'k', 'letters':/[\u006B\u24DA\uFF4B\u1E31\u01E9\u1E33\u0137\u1E35\u0199\u2C6A\uA741\uA743\uA745\uA7A3]/g},
        {'base':'l', 'letters':/[\u006C\u24DB\uFF4C\u0140\u013A\u013E\u1E37\u1E39\u013C\u1E3D\u1E3B\u017F\u0142\u019A\u026B\u2C61\uA749\uA781\uA747]/g},
        {'base':'lj','letters':/[\u01C9]/g},
        {'base':'m', 'letters':/[\u006D\u24DC\uFF4D\u1E3F\u1E41\u1E43\u0271\u026F]/g},
        {'base':'n', 'letters':/[\u006E\u24DD\uFF4E\u01F9\u0144\u00F1\u1E45\u0148\u1E47\u0146\u1E4B\u1E49\u019E\u0272\u0149\uA791\uA7A5]/g},
        {'base':'nj','letters':/[\u01CC]/g},
        {'base':'o', 'letters':/[\u006F\u24DE\uFF4F\u00F2\u00F3\u00F4\u1ED3\u1ED1\u1ED7\u1ED5\u00F5\u1E4D\u022D\u1E4F\u014D\u1E51\u1E53\u014F\u022F\u0231\u00F6\u022B\u1ECF\u0151\u01D2\u020D\u020F\u01A1\u1EDD\u1EDB\u1EE1\u1EDF\u1EE3\u1ECD\u1ED9\u01EB\u01ED\u00F8\u01FF\u0254\uA74B\uA74D\u0275]/g},
        {'base':'oi','letters':/[\u01A3]/g},
        {'base':'ou','letters':/[\u0223]/g},
        {'base':'oo','letters':/[\uA74F]/g},
        {'base':'p','letters':/[\u0070\u24DF\uFF50\u1E55\u1E57\u01A5\u1D7D\uA751\uA753\uA755]/g},
        {'base':'q','letters':/[\u0071\u24E0\uFF51\u024B\uA757\uA759]/g},
        {'base':'r','letters':/[\u0072\u24E1\uFF52\u0155\u1E59\u0159\u0211\u0213\u1E5B\u1E5D\u0157\u1E5F\u024D\u027D\uA75B\uA7A7\uA783]/g},
        {'base':'s','letters':/[\u0073\u24E2\uFF53\u00DF\u015B\u1E65\u015D\u1E61\u0161\u1E67\u1E63\u1E69\u0219\u015F\u023F\uA7A9\uA785\u1E9B]/g},
        {'base':'t','letters':/[\u0074\u24E3\uFF54\u1E6B\u1E97\u0165\u1E6D\u021B\u0163\u1E71\u1E6F\u0167\u01AD\u0288\u2C66\uA787]/g},
        {'base':'tz','letters':/[\uA729]/g},
        {'base':'u','letters':/[\u0075\u24E4\uFF55\u00F9\u00FA\u00FB\u0169\u1E79\u016B\u1E7B\u016D\u00FC\u01DC\u01D8\u01D6\u01DA\u1EE7\u016F\u0171\u01D4\u0215\u0217\u01B0\u1EEB\u1EE9\u1EEF\u1EED\u1EF1\u1EE5\u1E73\u0173\u1E77\u1E75\u0289]/g},
        {'base':'v','letters':/[\u0076\u24E5\uFF56\u1E7D\u1E7F\u028B\uA75F\u028C]/g},
        {'base':'vy','letters':/[\uA761]/g},
        {'base':'w','letters':/[\u0077\u24E6\uFF57\u1E81\u1E83\u0175\u1E87\u1E85\u1E98\u1E89\u2C73]/g},
        {'base':'x','letters':/[\u0078\u24E7\uFF58\u1E8B\u1E8D]/g},
        {'base':'y','letters':/[\u0079\u24E8\uFF59\u1EF3\u00FD\u0177\u1EF9\u0233\u1E8F\u00FF\u1EF7\u1E99\u1EF5\u01B4\u024F\u1EFF]/g},
        {'base':'z','letters':/[\u007A\u24E9\uFF5A\u017A\u1E91\u017C\u017E\u1E93\u1E95\u01B6\u0225\u0240\u2C6C\uA763]/g}
    ];

    for(var i=0; i<defaultDiacriticsRemovalMap.length; i++) {
        str = str.replace(defaultDiacriticsRemovalMap[i].letters, defaultDiacriticsRemovalMap[i].base);
    }

    // \W is the negation of shorthand \w for [A-Za-z0-9_] word characters
    str = str.replace(/[#$%µ§]+/g, "");

    return str;

}

$.fn.serializeObject = function()
{
    var o = {};
    var a = this.serializeArray();
    $.each(a, function() {
        if (o[this.name]) {
            if (!o[this.name].push) {
                o[this.name] = [o[this.name]];
            }
            o[this.name].push(this.value || '');
        } else {
            o[this.name] = this.value || '';
        }
    });
    return o;
};